import React, { useEffect } from "react"
import { Global } from "@emotion/core"
import { css, Styled } from "theme-ui"
import logo from "../images/logo.svg"
import { SITE_STATIC_DATA } from "../constant"
import MobileMenu from "./mobile-menu"
import SEO from "./seo"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import Hidden from "@material-ui/core/Hidden"
import clsx from "clsx"
import styled from "styled-components"

const getQueryParams = (params, url) => {
  let href = url
  //this expression is to get the query strings
  let reg = new RegExp("[?&]" + params + "=([^&#]*)", "i")
  let queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}

function Layout({ children, className }) {
  const [removeHeader, setRemoveHeader] = React.useState(false)
  const [removeFooter, setRemoveFooter] = React.useState(false)

  useEffect(() => {
    if (getQueryParams("contentOnly", window.location.href)) {
      setRemoveHeader(true)
      setRemoveFooter(true)
    }

    if (getQueryParams("shortlink", window.location.href)) {
      window.location.href = "/pricing"
    }

    const routesWithoutHeader = ["/status", "/status/"]
    const routesWithout = ["/support", "/support/"]

    if (routesWithoutHeader.includes(window.location.pathname)) {
      setRemoveHeader(true)
    }

    if (routesWithout.includes(window.location.pathname)) {
      setRemoveHeader(true)
      setRemoveFooter(true)
    }
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      color: "inherit",
      // padding: theme.spacing(1),
      boxShadow:
        "0 0 1px 0 rgb(9 30 66 / 31%), 0 4px 8px -2px rgb(9 30 66 / 25%);",
      borderRadius: "8px",
      padding: "16px",
    },
  }))
  const classes = useStyles()

  return (
    <div>
      {/* // <Styled.root data-testid="theme-root"> */}
      <Global
        styles={css({
          "*": {
            boxSizing: `inherit`,
            "&:before": {
              boxSizing: `inherit`,
            },
            "&:after": {
              boxSizing: `inherit`,
            },
          },
          html: {
            fontSize: `18spx`,
          },
          body: {
            fontFamily: "Montserrat, sans-serif",
            margin: 0,
            padding: 0,
            boxSizing: `border-box`,
            textRendering: `optimizeLegibility`,
            WebkitFontSmoothing: `antialiased`,
            MozOsxFontSmoothing: `grayscale`,
          },
        })}
      />

      <SEO />
      {/* <CookieConsent /> */}

      <div className="flex flex-col min-h-screen">
        {removeHeader === false ? (
          <header>
            <div className="mx-auto" style={{padding:"0.8rem .5rem 0.8rem .5rem"}}>
              <div className="flex flex-wrap justify-between self-center">
                <div className="flex">
                  <div className="flex-initial text-center mr-2">
                    <a
                      href="/"
                      className="text-sm leading-6 font-medium text-red-200 hover:text-red-400 focus:outline-none focus:text-red-900 transition ease-in-out duration-150"
                    >
                      <img
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingleft: 4,
                          height: 34,
                        }}
                        src={logo}
                        alt="Logo"
                      />
                    </a>
                  </div>
                </div>

                <Hidden xsDown>
                  <div
                    className="self-center"
                    style={{ flex: 1, marginLeft: "1.5em" }}
                  >
                    <div
                      className="flex"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div>
                        {/* <a
                          aria-label={"product"}
                          className={clsx(
                            "pointer bg-zone-ctatext-xs font-bold px-4 rounded",
                            {
                              active: false,
                            }
                          )}
                          onClick={handleClick}
                        >
                          Product
                          {open ? (
                            <KeyboardArrowUpRoundedIcon />
                          ) : (
                            <KeyboardArrowDownRoundedIcon />
                          )}
                        </a> */}
                        {SITE_STATIC_DATA.primaryMenu.map((menu, index) => (
                          <Link
                            to={menu.path}
                            key={index}
                            aria-label={menu.title}
                            className={clsx(
                              "bg-zone-ctatext-xs font-bold px-4 rounded",
                              {
                                active: false,
                                "bg-zone-cta padding": menu.isEmphasized,
                              }
                            )}
                          >
                            {menu.title}
                          </Link>
                        ))}
                      </div>
                      <div>
                       <Link
                      to={"/pricing"}
                      className="cursor-pointer shadow bg-zone-cta text-xs focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded" style={{fontSize: "14px"}}
                    >
                      GET STARTED
                    </Link>
                      </div>
                    </div>
                  </div>
                </Hidden>
                <Hidden smUp>
                  <MobileMenu></MobileMenu>
                </Hidden>
              </div>
            </div>
          </header>
        ) : (
          ""
        )}

        {/* Pages Goes Here */}

        <main className={"flex-grow"}>{children}</main>

        {/* Footer Goes Here */}
        {removeFooter === false ? (
          <>
            <footer className="footer-1 bg-gray-100 py-8 sm:py-12">
              <div className="container mx-auto px-4">
                <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
                  <div className="flex-1 px-4 sm:w-1/2 md:w-1/3 xl:w-1/4">
                    <div className="flex">
                      <a
                        href="/"
                        className="text-sm leading-6 font-medium text-red-200 hover:text-red-400 focus:outline-none focus:text-red-900 transition ease-in-out duration-150"
                      >
                        <img
                          style={{
                            height: 28,
                          }}
                          src={logo}
                          alt="Logo"
                        />
                      </a>
                    </div>
                    <div className="flex mt-2">
                      <a
                        href="https://www.instagram.com/tobeingzone" target="_blank"
                        className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/beingzone/" target="_blank"
                        className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                      <a
                        href="https://www.twitter.com/tobeingzone" target="_blank"
                        className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                      <a
                        href="https://www.facebook.com/tobeingzone" target="_blank"
                        className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
                      >
                        <i className="fab fa-facebook" />
                      </a>
                    </div>
                    <div className="flex mt-2">
                      <div style={{ fontSize: 12 }} className="text-xs ">
                        &copy; 2022 Being.Zone All Rights Reserved
                      </div>
                    </div>
                  </div>
                  <div className="px-4 sm:w-1/2 md:w-1/3 xl:w-1/4 mt-8 sm:mt-0">
                    <ul className="list-none footer-links">

                      <li className="mb-1">
                        <a
                          href="/how-it-works-individuals"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          How It Works
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          href="/business-solutions"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Business Solutions
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          target="_blank"
                          href="https://form.typeform.com/to/bbjcdIjn"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Request a demo
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          href="/for-individuals"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          For Individuals
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          href="/pricing"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Individual GET STARTED
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          target="_blank"
                          href="https://form.typeform.com/to/DQ3mnyjA"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          TRY COACHING FREE SESSION
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="px-4 sm:w-1/2 md:w-1/3 xl:w-1/4 mt-8 md:mt-0">
                    <ul className="list-none footer-links">
                  
                      <li className="mb-2">
                        <a
                          href="/about"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          target="_blank"
                          href="https://form.typeform.com/to/zYxMCRXn"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Become a BeingZone Coach
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="/terms"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="/privacy"
                          className="text-sm leading-6 font-medium focus:outline-none transition ease-in-out duration-150"
                        >
                          Privacy Policy
                        </a>
                      </li> 
                    </ul>
                  </div>
                  <div className="px-4 sm:w-1/2 md:w-1/3 xl:w-1/4 mt-8 md:mt-0">
                  <ul className="list-none footer-links">
                    <li>
                    <a href="tel:+919980836911" style={{
                            fontSize: 20,
                          }}>+91 998 083 6911</a>
                    </li>
                    <li className="mb-2">
                    <a href="mailto:contact@being.zone" >contact@being.zone</a>
                    </li>

                    <address className="not-italic mb-4 text-sm">
                      #14, Srinidhi Building <br />
                      J.P Nagar 8th phase
                      <br />
                      Bangalore 560076
                    </address>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </>
        ) : (
          ""
        )}
      </div>
      {/* </Styled.root> */}
    </div>
  )
}
export default Layout
