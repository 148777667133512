import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { SITE_STATIC_DATA } from "../constant"
import Collapse from "@material-ui/core/Collapse"

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: 272,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function MobileMenu() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [openNested, setOpenNested] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClick = (path) => {
    window.location.href = path
  }

  const handleNested = () => {
    setOpenNested(!openNested)
  }

  const nestedList = [
    {
      title: "Work log",
      path: "/",
      isExternal: true,
      isNested: false,
      isEmphasized: false,
    },
    {
      title: "PR Timeline",
      path: "/",
      isExternal: true,
      isNested: false,
      isEmphasized: false,
    },
    {
      title: "Dev Timecard",
      path: "/",
      isExternal: true,
      isNested: false,
      isEmphasized: false,
    },

    {
      title: "For Engineering Manager",
      path: "/",
      isExternal: true,
      isNested: false,
      isEmphasized: false,
    },

    {
      title: "For Software Developer",
      path: "/",
      isExternal: true,
      isNested: false,
      isEmphasized: false,
    },
  ]

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: true,
      })}
      role="presentation"
      onKeyDown={handleDrawerClose}
    >
      <List>
        {/* Start Product Menu */}
        {/* <ListItem button onClick={handleNested}>
          <ListItemText primary="Product" />
          {openNested ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openNested} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {nestedList.map((menu, index) => (
              <ListItem
                className={classes.nested}
                button
                key={index}
                onClick={() => handleClick(menu.path)}
              >
                <ListItemText primary={menu.title} />
              </ListItem>
            ))}
          </List>
        </Collapse>
        <Divider/> */}

        {/* End Product Menu */}
        {SITE_STATIC_DATA.primaryMenu.map((menu, index) => (
          <ListItem button key={index} onClick={() => handleClick(menu.path)}>
            <ListItemText primary={menu.title} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor={"right"} open={open} onClose={handleDrawerClose}>
        {list("right")}
      </Drawer>
    </React.Fragment>
  )
}
