export const SECURITY_ENDPOINT = ""

// Site Data
export const SITE_STATIC_DATA = {
  primaryMenu: [
    {
      title: "How It works",
      path: "/how-it-works-individuals",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },
    /*
    {
      title: "How It works",
      path: "/how-it-works-individuals",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },
    {
      title: "For Organizations",
      path: "/for-organizations",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },
    {
      title: "Being Programs",
      path: "/being-programs",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },*/
      {
      title: "For Individuals",
      path: "/for-individuals",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },
    {
      title: "Business Solutions",
      path: "/business-solutions",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },/*
    {
      title: "Join Being Circle",
      path: "/being-circle",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },*/
    {
      title: "About",
      path: "/about",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },/*
    {
      title: "Login",
      path: "/",
      isExternal: false,
      isNested: false,
      isEmphasized: false,
    },*/
  ],
  credMenu: [],
  socialNav: [],
}